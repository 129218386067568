<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="3000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-card>
        <v-card-title>
            <v-row class="mx-2" justify="space-between">
                <div>Presets</div><div> 
                     <v-btn style="margin-left:150px" color="primary" class="logi" dark
                    @click="add_new_presets_dialog = true"><v-icon>mdi-plus</v-icon></v-btn>
                   
                <v-dialog v-model="add_new_presets_dialog" persistent >

                  <v-card>
                    <v-card-title>
                      Add New Presets
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="4" md="4">
                            <v-text-field autocomplete="off" outlined label="Peset Name" 
                              return-object multiple v-model="presets_name" ></v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="3" md="3">
                            <v-select autocomplete="off" outlined label="Organization" :items="orglist"
                              return-object multiple v-model="selected_organization" item-text="org_name"
                              item-value="org_id"></v-select>
                          </v-col>
                      </v-row>
                      <v-row  v-if="Array.isArray(selected_organization) && selected_organization.length">
                        <v-col v-for="(item,index) in selected_organization" :key=index cols="12" sm='4' md=4>
                          <fieldset class="pa-3">
                            <legend>{{item.org_name}}</legend>  
                          <v-row>
                              <v-col cols="12" sm="12" md="12">
                                <v-select autocomplete="off" outlined label="Program Type" :items="item.program_type_list"
                                @change="getProgramFromProgramtype(item)" return-object multiple v-model="item.selected_program_type" item-text="programtype_name"
                                item-value="programtype_id"></v-select>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-select v-if="Array.isArray(item.get_program) && item.get_program.length" autocomplete="off" outlined label="Program" :items="item.get_program"
                                 return-object multiple v-model="item.selected_program" item-text="program_name"
                                item-value="program_id"></v-select>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-select v-if="Array.isArray(item.get_program) && item.get_program.length" autocomplete="off" outlined label="Year" :items="item.year_list"
                                 return-object multiple v-model="item.selected_year" item-text="year_name"
                                item-value="year_id"></v-select>
                              </v-col>
                            </v-row>
                          </fieldset>
                        </v-col>
                      </v-row>
                      <v-row justify="center" align="center">
                        <v-btn @click="savepresets()"  dark class="logi mx-2">Save</v-btn>
                        <v-btn @click="add_new_presets_dialog=false" class="white mx-2">Cancel</v-btn>
                    </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                </div>
            </v-row>
        </v-card-title>
    </v-card>

<div>
  <v-data-table
    :headers="preset_header"
    :items="preset_data"
  
    :expanded.sync="expanded"
    item-key="preset_name"
    show-expand
    class="elevation-1 my-2"
    
  >
    
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
      
        <v-row justify="center" align="center">
          
          <v-col class="ma-1"  style="border-style:solid;border-color:grey;border-width:1px; height:100px;" v-for="(item1,index) in item.cops_list" :key="index" cols="12" sm="3" md="3">
           
            <v-row no-gutters justify="center" align="center">
              <v-col  cols="12" sm="10" md="10">
                {{item1.cops_name}}
              </v-col>
              <v-col  cols="12" sm="1" md="1">
               <center> <v-icon class=" red--text" @click="deleteSinglePreset(item1.cops_id,item)">mdi mdi-delete</v-icon></center>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-icon class="ma-2 blue--text" x-large @click="open_add_cops_dialog(item)">mdi mdi-plus-circle-outline</v-icon>
        </v-row>
      </td>
     
    </template>
    <template v-slot:item.action="{ item }">
      <div>
           <v-icon class=" red--text" @click="deletePreset(item.preset_id)">mdi mdi-delete</v-icon>
       </div>
     </template>
  </v-data-table>
  <v-dialog v-model="cops_dialog" persistent >

    <v-card>
      <v-card-title>
        Add Presets
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4" md="4">
              <v-text-field autocomplete="off" outlined label="Peset Name" 
                return-object multiple disabled v-model="edit_presets_name" ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3">
              <v-select autocomplete="off" outlined label="Organization" :items="orglist"
                return-object multiple v-model="selected_organization" item-text="org_name"
                item-value="org_id"></v-select>
            </v-col>
        </v-row>
        <v-row  v-if="Array.isArray(selected_organization) && selected_organization.length">
          <v-col v-for="(item,index) in selected_organization" :key=index cols="12" sm='4' md=4>
            <fieldset class="pa-3">
              <legend>{{item.org_name}}</legend>  
            <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-select autocomplete="off" outlined label="Program Type" :items="item.program_type_list"
                  @change="getProgramFromProgramtype(item)" return-object multiple v-model="item.selected_program_type" item-text="programtype_name"
                  item-value="programtype_id"></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select v-if="Array.isArray(item.get_program) && item.get_program.length" autocomplete="off" outlined label="Program" :items="item.get_program"
                   return-object multiple v-model="item.selected_program" item-text="program_name"
                  item-value="program_id"></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select v-if="Array.isArray(item.get_program) && item.get_program.length" autocomplete="off" outlined label="Year" :items="item.year_list"
                   return-object multiple v-model="item.selected_year" item-text="year_name"
                  item-value="year_id"></v-select>
                </v-col>
              </v-row>
            </fieldset>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-btn @click="addmorepresets()"  dark class="logi mx-2">Add</v-btn>
          <v-btn @click="cops_dialog=false" class="white mx-2">Cancel</v-btn>
      </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        snackbar:false,
        color:"",
        snackbar_msg:"",

        dialog_branch:false,
        selectedprogramlist:[],
        program_list:[],
        selectedyearlist:"",
        year_list1:[],
        selectedprgtype:"",
        programtype_list:[],
        selectedorg:"",
        

        presets_name:"",
        add_new_presets_dialog:false,
        selected_organization:[],
        orglist:[],
        selected_program_type:[],
        selected_program:[],
        preset_header:[
          {  text: 'Preset Name',
            align: 'start',
            sortable: true,
            value: 'preset_name',},
            {  text: 'Action',
            align: 'start',
            sortable: false,
            value: 'action',}
        ],
        preset_data:[],
        expanded: [],
        cops_dialog:false,

        edit_presets_name:"",
        add_preset_id:"",
      };
    },
    mounted() {
      this.init();
    },
    computed: {
   
    likesAllFruit () {
        return this.selectedprogramlist.length === this.program_list.length
      },
      likesSomeFruit () {
        return this.selectedprogramlist.length > 0 && !this.likesAllFruit
      },
      icon () {
        if (this.likesAllFruit) return 'mdi-close-box'
        if (this.likesSomeFruit) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
  },
    methods: {
      addmorepresets()
      {
        var main_array=[];
        for(var x in this.selected_organization)
        {
            if(!this.selected_organization[x].org_id)
            {
              this.showSnackbar("red","Please Select Organization."); 
              return;
            }
            if( !(Array.isArray(this.selected_organization[x].selected_program_type) && this.selected_organization[x].selected_program_type.length) )
            {
              this.showSnackbar("red","Please Select "+this.selected_organization[x].org_name+" Program Type."); 
              return;
            }
            if( !(Array.isArray(this.selected_organization[x].selected_program) && this.selected_organization[x].selected_program.length ))
            {
              this.showSnackbar("red","Please Select "+this.selected_organization[x].org_name+" Program."); 
              return;
            }
            if( !(Array.isArray(this.selected_organization[x].selected_year) && this.selected_organization[x].selected_year.length ))
            {
              this.showSnackbar("red","Please Select "+this.selected_organization[x].org_name+" Year."); 
              return;
            }
            var hash_map = {};
           hash_map={"org_id":this.selected_organization[x].org_id,"selected_program":this.selected_organization[x].selected_program,"selected_year":this.selected_organization[x].selected_year};
          

          main_array.push(hash_map);
        }

        if(!this.edit_presets_name)
        {
          this.showSnackbar("red","Please Enter Preset Name."); 
          return;
        }
        var params={presets_data:main_array,presets_name:this.edit_presets_name,presets_id:this.add_preset_id};
        axios.post("/TPONew/addmorepresets",params).then((res) => {
        if (res.data.msg == "200") {
          this.init();
          this.showSnackbar("green", "Successfully Added."); 
          this.cops_dialog=false;
        }
        else
        {
          this.showSnackbar("red", res.data.msg); 
        }
      });
      },
      open_add_cops_dialog(item)
      {
      
        this.edit_presets_name=item.preset_name;
        this.add_preset_id=item.preset_id
        this.cops_dialog=true;
        
      },
      deletePreset(ps_id,item1)
      {
        if(confirm("Are You Sure?"))
        {
          var params={ps_id:ps_id};
                axios.post("/TPONew/deletePreset",params).then((res) => {
                if (res.data.msg == "200") {
                  const index = this.preset_data.findIndex(item => item.preset_id == ps_id);
                  this.preset_data.splice(index,1)
                  this.showSnackbar("green", "Successfully Deleted.."); 
                  return;
                }
                else
                {
                  this.showSnackbar("red", res.data.msg); 
                }
              });
            }
      },
      deleteSinglePreset(cops_id,item1)
      {
      
        var params={cops_id:cops_id};
        axios.post("/TPONew/deleteSinglePreset",params).then((res) => {
        if (res.data.msg == "200") {
          const index = item1.cops_list.findIndex(item => item.cops_id == cops_id);
          item1.cops_list.splice(index,1)
          this.showSnackbar("green", "Successfully Deleted.."); 
          return;
        }
        else
        {
          this.showSnackbar("red", res.data.msg); 
        }
      });
      },
      savepresets()
      {
        var main_array=[];
        for(var x in this.selected_organization)
        {
            if(!this.selected_organization[x].org_id)
            {
              this.showSnackbar("red","Please Select Organization."); 
              return;
            }
            if( !(Array.isArray(this.selected_organization[x].selected_program_type) && this.selected_organization[x].selected_program_type.length) )
            {
              this.showSnackbar("red","Please Select "+this.selected_organization[x].org_name+" Program Type."); 
              return;
            }
            if( !(Array.isArray(this.selected_organization[x].selected_program) && this.selected_organization[x].selected_program.length ))
            {
              this.showSnackbar("red","Please Select "+this.selected_organization[x].org_name+" Program."); 
              return;
            }
            if( !(Array.isArray(this.selected_organization[x].selected_year) && this.selected_organization[x].selected_year.length ))
            {
              this.showSnackbar("red","Please Select "+this.selected_organization[x].org_name+" Year."); 
              return;
            }
            var hash_map = {};
           hash_map={"org_id":this.selected_organization[x].org_id,"selected_program":this.selected_organization[x].selected_program,"selected_year":this.selected_organization[x].selected_year};
          

          main_array.push(hash_map);
        }

        if(!this.presets_name)
        {
          this.showSnackbar("red","Please Enter Preset Name."); 
          return;
        }
        var params={presets_data:main_array,presets_name:this.presets_name};
        axios.post("/TPONew/savepresets",params).then((res) => {
        if (res.data.msg == "200") {
          this.showSnackbar("green", "Successfully Save."); 
          this.init();
          this.add_new_presets_dialog=false;
        }
        else
        {
          this.showSnackbar("red", res.data.msg); 
        }
      });
      },
        init() {
      axios.post("/TPONew/getOrganizationWithProgram").then((res) => {
        if (res.data.msg == "200") {
          this.orglist = res.data.orglist;
          this.preset_data = res.data.preset_data;
        }
      });
    },
    getProgramFromProgramtype(item)
    {
      item.get_program=[];
       for(var i in item.selected_program_type)
       {
        for(var j in item.selected_program_type[i].program_list)
        item.get_program.push(item.selected_program_type[i].program_list[j]);
       }
    },
    getprogramTypelistforadd() {
      this.programtype_list = []
      var params = {
        organization: this.selectedorg,
        companyoffering: this.scheduling_id,
      };

      axios.post("/TPOCompanyScheduling/getprogramTypelistforadd", params).then((res) => {
        //console.log(res.data);
        if (res.data.msg == "200") {
          this.programtype_list = res.data.programtype_list;
          //console.log("this.programtype_list");
          //console.log(this.programtype_list);
        } else { }
      });
    },
    getprogramlistforadd() {
      this.program_list = []
      this.year_list1 = []
      var params = {
        organization: this.selectedorg,
        companyoffering: this.scheduling_id,
        programtype: this.selectedprgtype,
      };

      axios.post("/TPOCompanyScheduling/getprogramlist", params).then((res) => {
        if (res.data.msg == "200") {
          this.program_list = res.data.programlist;
          this.year_list = res.data.yearlist;
          this.year_list1 = res.data.yearlist;
        }
      });
    },
      showSnackbar(clr, msg) {
        this.snackbar = true;
        this.color = clr;
        this.snackbar_msg = msg;
    },
    toggle () {
        this.$nextTick(() => {
          if (this.likesAllFruit) {
            this.selectedprogramlist = []
          } else {
            this.selectedprogramlist = []
            for(var i in this.program_list)
            {
            
              this.selectedprogramlist.push(this.program_list[i].id);
            }
           
          }
        })
      },
    },
  };
  </script>